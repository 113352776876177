import { useState, useEffect, useContext } from 'react';
import { DatePicker, Empty, Spin, Tooltip, Button, Card, Space, Row, Col } from 'antd';
import { useAxios } from '../../../../../util/AxiosUtil';
import dayjs from 'dayjs';
import { Column } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { useTranslation } from "react-i18next";
import { LocaleContext } from '../../../../../context/LocaleContext';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';

const ControlTab = ({ boilerRoomId, unit, refresh }) => {

    const { i18n, t } = useTranslation();
    const { locale, setLocale } = useContext(LocaleContext);

    const dateFormat = "YYYY-MM-DD";
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { RangePicker } = DatePicker;
    
    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
    const [graphData, setGraphData] = useState([]);
    const [emptyGraphData, setEmptyGraphData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [groupBy, setGroupBy] = useState("hour");
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

    useEffect(() => {
      if (dateRange !== null)
        customFetch(dateRange[0], dateRange[1]);
    }, [boilerRoomId, dateRange, unit, refresh, groupBy, locale]);

    useEffect(() => {
      function handleResize() {
          setIsMobile(window.innerWidth < 700)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, []);

    const customFetch = async (dateFrom, dateTo) => {
        setIsLoading(true);

        try {
          const priceEe = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/priceEe/'+groupBy+'/'+dateFrom.format(dateFormat)+'/'+dateTo.format(dateFormat));
          // add - one extra day - because we need calculate COP-1H value
          const heatPumpCop = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/cop/'+groupBy+'/'+dateFrom.subtract(1, 'day').format(dateFormat)+'/'+dateTo.add(1, 'day').format(dateFormat));
          const workingTC = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/workingTC/'+groupBy+'/'+dateFrom.subtract(1, 'day').format(dateFormat)+'/'+dateTo.add(1, 'day').format(dateFormat));

          const priceZp = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/priceZp/'+groupBy+'/'+dateFrom.format(dateFormat)+'/'+dateTo.format(dateFormat));
          const zpNormative = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/zpNormative/'+groupBy+'/'+dateFrom.format(dateFormat)+'/'+dateTo.add(1, 'day').format(dateFormat));

          var sumCostEeData = [];
          priceEe.data.sort(function(a,b){
            var dateA = new Date(a.date).getTime();
            var dateB = new Date(b.date).getTime();
            return dateA > dateB ? 1 : -1;  
          });

          for (var k = 0; k < priceEe.data.length; k++) {
            for (var l = 0; l < heatPumpCop.data.length; l++) {
              if (dayjs(priceEe.data[k].date).format("DD.MM.YYYY HH:mm") === dayjs(heatPumpCop.data[l].date).add(1,'h').format("DD.MM.YYYY HH:mm")) {

                let runningTc = 0;
                for (var j = 0; j < workingTC.data.length; j++) {
                  if (dayjs(priceEe.data[k].date).format("DD.MM.YYYY HH:mm") === dayjs(workingTC.data[j].date).add(1,'h').format("DD.MM.YYYY HH:mm"))
                    runningTc = workingTC.data[j].value;
                }

                let cop = (runningTc == 0 || heatPumpCop.data[l].value == 0) ? 3 : heatPumpCop.data[l].value;
                sumCostEeData.push({
                  value: cop > 0 ? priceEe.data[k].value / cop : 0,
                  cop: cop > 0 ? 'defined' : 'undefined',
                  type: t("Cena MWh tepla vyrobenú z EE"),
                  date: dayjs(priceEe.data[k].date).format("DD.MM.YYYY HH:mm")
                });
              }
            }
          }
          
          var sumCostZpData = [];  
          for (var k = 0; k < priceZp.data.length; k++) {
            for (var l = 0; l < zpNormative.data.length; l++) {
              if (dayjs(priceZp.data[k].date).format("DD.MM.YYYY HH") === dayjs(zpNormative.data[l].date).add(1,'h').format("DD.MM.YYYY HH")) {
                for (var j = 0; j < sumCostEeData.length; j++) {
                  if (dayjs(priceZp.data[k].date).format("DD.MM.YYYY HH:mm") === sumCostEeData[j].date) {
                    let res = priceZp.data[k].value / (zpNormative.data[l].value/100);
                    sumCostZpData.push({
                      value: Number.isFinite(res) ? res : priceZp.data[k].value,
                      type: t("Cena MWh tepla vyrobenú zo ZP"),
                      date: dayjs(priceZp.data[k].date).format("DD.MM.YYYY HH:mm")
                    });
                  }
                }
              }
            }
          }

          // ak costZp > costEe -> Elektrina : Plyn
          var operationData = [];
          for (var k = 0; k < sumCostZpData.length; k++) {
            for (var l = 0; l < sumCostEeData.length; l++) {
              if (sumCostZpData[k].date === sumCostEeData[l].date) {
                operationData.push({
                  value: (sumCostEeData[l].cop != 'undefined' && sumCostZpData[k].value > sumCostEeData[l].value) ? t("page.electricity") : t("page.gass"),
                  type: t("Prevádzka"),
                  date: sumCostZpData[k].date
                });
              }
            }
          }

          var finalData = sumCostEeData.concat(sumCostZpData).concat(operationData);

          setEmptyGraphData(false);
          if (finalData.length === 0)
            setEmptyGraphData(true);
    
          setGraphData(finalData);
          setIsLoading(false);
        } catch(err) {
          console.log(err);
        }
    };

    function onDateChange(date) {

      if (date !== null) {
        var changedDateFrom = dayjs(date);
        var changedDateTo = dayjs(date);

        changeDateRange([changedDateFrom, changedDateTo]);
      }
    }
  
    const changeDateRange = (value) => {
      setDateRange(value);
    };

    const prevStep = () => {
      changeDateRange([dayjs(dateRange[0]).subtract(1,'d'), dayjs(dateRange[1]).subtract(1,'d')]);
    }
    
    const nextStep = () => {
      if (!dayjs(dateRange[1]).isSame(dayjs())) {
        changeDateRange([dayjs(dateRange[0]).add(1,'d'), dayjs(dateRange[1]).add(1,'d')]);
      } else {
        customFetch(dateRange[0], dateRange[1]);
      }
    }

    const refreshChart = () => {
      customFetch(dateRange[0], dateRange[1]);
    }

    const config = {
      data: graphData,
      isGroup: true,
      seriesField: 'type',
      xField: 'date',
      yField: 'value',
      isStack: true,
      legend: {
        position: "top-left",
      },
      height: 300,
      tooltip: {
        formatter: (x) => {
          if (x.type === t("Prevádzka")) {
            return { name: x.type, value: x.value };
          } else if (x.type === t("Cena MWh tepla vyrobenú z EE")) {
            return { name: x.type, value: x.cop !== 'undefined' ? parseFloat(x.value).toFixed(2) + ' €/MWh' : x.cop };
          } else {
            return { name: x.type, value: parseFloat(x.value).toFixed(2) + ' €/MWh' };
          }
        },
      },
    };

    return (
      <Spin spinning={isLoading}>
        <Card
          size="small" loading={isLoading}
          style={{ marginTop: 10, paddingBottom: 15 }}
          title={
            <>
              <Row gutter={[5,10]}>
                <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                  <DatePicker 
                    value={dateRange[0]}
                    onChange={onDateChange}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                    maxDate={dayjs()}
                  />
                </Col>
                <Col flex="auto">
                  <Row justify="end">
                    <Col>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                        </Tooltip>
                      </Space>
                      {/*
                      <Space style={{ paddingLeft: 10 }}>
                        <Tooltip title="Export" style={{ margin: 15 }} >
                          <Button type="dashes" icon={<DownloadOutlined />} />
                        </Tooltip>
                      </Space>
                      */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
          }>
          {emptyGraphData ?
            <Empty />
          :
            <ReactG2Plot Ctor={Column} options={config} />
          }
        </Card>
      </Spin>
    );
};

export default ControlTab;