import { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Button, Descriptions } from 'antd';
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';

import isInRole from '../../security/IsInRole';
import { PlusCircleOutlined } from '@ant-design/icons';

import BranchSensorTable from '../branch_sensor/BranchSensorTable.js';
import BranchSensorInsert from '../branch_sensor/BranchSensorInsert.js';
import BuildingTable from '../building/BuildingTable.js';
import BuildingInsert from '../building/BuildingInsert.js';

const Branch = () => {
    
    let params = useParams();
    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const [visibleCsForm, setVisibleCsForm] = useState(false);
    const [refreshCsForm, setRefreshCsForm] = useState(false);
    const [visibleBuildingForm, setVisibleBuildingForm] = useState(false);
    const [refreshBuildingForm, setRefreshBuildingForm] = useState(false);

    const [branchData, setBranchData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, [params.id]);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/branch/'+params.boilerRoomId+'/'+params.id)
      .then(response => {
          setBranchData(response.data);
          setBoilerRoomData(response.data.boilerRoom);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const showInsertCsForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleCsForm(false);
          setRefreshCsForm(true);
      } else {
          setVisibleCsForm(value);
      }
    }, []);

    const showInsertBuildingForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleBuildingForm(false);
          setRefreshBuildingForm(true);
      } else {
          setVisibleBuildingForm(value);
      }
    }, []);

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Row>
            <Col span={24} style={{ paddingTop: 10 }}>
                <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>
                  <Descriptions title={t("page.branch")+": " + branchData.name} size='small'>
                    <Descriptions.Item label={t("page.boiler_room")}>
                      <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/"+boilerRoomData.id}> {boilerRoomData.name}</Link>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
            </Col>
        </Row>
        
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 0 }}>
          <Col span={24}>
              <Card size="small" title={t("page.sensors_consume")+":"}
                extra={ isInRole(['enerpo_admin']) ? <Button type="text" onClick={showInsertCsForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }} />}>{t("page.sensor_consume_create")}</Button> : <></>}
                loading={isLoading}
              >
                  <BranchSensorTable branchData={branchData} setRefresh={setRefreshCsForm} refresh={refreshCsForm} />
                  <BranchSensorInsert branchData={branchData} setVisible={showInsertCsForm} visible={visibleCsForm} />
              </Card>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span={24}>
              <Card size="small" title={t("page.buildings")+":"}
                extra={ isInRole(['enerpo_admin']) ? <Button type="text" onClick={showInsertBuildingForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }} />}>{t("page.building_create")}</Button> : <></>}
                loading={isLoading}
              >
                  <BuildingTable branchData={branchData} setRefresh={setRefreshBuildingForm} refresh={refreshBuildingForm} />
                  <BuildingInsert branchData={branchData} setVisible={showInsertBuildingForm} visible={visibleBuildingForm} />
              </Card>
          </Col>
        </Row>
      </>
    );
};

export default Branch;