import { useState, useEffect, useCallback } from 'react';
import { Tabs, Button, Card, Row, Col, Typography, Breadcrumb } from 'antd';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import Unauthorized from '../error/Unauthorized';

import BoilerRoomMap from '../boiler_room/BoilerRoomMap.js';
import BoilerRoomTable from '../boiler_room/BoilerRoomTable.js';
import BoilerRoomInsert from '../boiler_room/BoilerRoomInsert.js';
import HeatPumpTable from '../heat_pump/HeatPumpTable.js';
import FweTable from '../fwe/FweTable.js';
import BatteryTable from '../battery/BatteryTable.js';

import { HomeOutlined } from '@ant-design/icons';

const Company = () => {
    
    const { i18n, t } = useTranslation();

    const [visibleForm, setVisibleForm] = useState(false);
    const [refreshForm, setRefreshForm] = useState(false);
    const [selectedKey, setSelectedKey] = useState();

    let params = useParams();
    const { TabPane } = Tabs;
    const { Title } = Typography;

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [companyData, setCompanyData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, [params.id]);

    const customFetch = async () => {
      if (!keycloak.authenticated)
        navigate(process.env.REACT_APP_ENERPO_URL);

      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/company/'+params.id)
      .then(response => {
          setCompanyData(response.data);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const showInsertForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleForm(false);
          setRefreshForm(true);
      } else {
          setVisibleForm(value);
      }
    }, []);

    return (
      <>
        {isInRole(['enerpo_web']) ?
          <>
            <Breadcrumb
              items={[
                { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/"}><HomeOutlined /></Link>, },
                { title: companyData.name},
              ]}
            />

            <Row>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Card bordered={true} styles={{body : {padding: 10}}}>
                  <Title level={5}>{t("page.company") + ": " + companyData.name}</Title>
                </Card>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
              <Col span={24}>
                  <Card size="small" loading={isLoading}>
                    <BoilerRoomMap companyData={companyData} height={250} zoom={12} refreshMap={refreshForm} />
                  </Card>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
              <Col span={24}>
                  <Card size="small" title={t("page.boiler_rooms")+":"}
                      extra={ <Button type="text" onClick={showInsertForm} icon={<PlusCircleOutlined />}>{t("page.add_boiler_room")}</Button> }
                      loading={isLoading}
                  >
                    <BoilerRoomTable companyId={companyData.id} setRefresh={setRefreshForm} refresh={refreshForm} />
                    <BoilerRoomInsert companyData={companyData} setVisible={showInsertForm} visible={visibleForm} />
                  </Card>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
              <Col span={24}>
                  <Card size="small" title={t("page.heat_pumps")+":"} loading={isLoading}>
                    <HeatPumpTable companyId={companyData.id} setRefresh={setRefreshForm} refresh={refreshForm} />
                  </Card>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
              <Col span={24}>
                  <Card size="small" title={t("page.fves")+":"} loading={isLoading}>
                    <FweTable companyId={companyData.id} setRefresh={setRefreshForm} refresh={refreshForm} />
                  </Card>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
              <Col span={24}>
                  <Card size="small" title={t("page.batteries")+":"} loading={isLoading}>
                    <BatteryTable companyId={companyData.id} setRefresh={setRefreshForm} refresh={refreshForm} />
                  </Card>
              </Col>
            </Row>
          </>
        : <Unauthorized />}
      </>
    );
};

export default Company;