import { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Button, Tooltip, Empty, Spin } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { useTranslation } from "react-i18next";
import { LeftOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const ThermoSensorMeasureLogChart = ({ thermoSensorId, setRefresh, refresh }) => {

  const dateFormat = "YYYY-MM-DD";
  const dateTimeFromat = "DD.MM.YYYY HH:mm";

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const [limit, setLimit] = useState(150);
  const [offset, setOffset] = useState(1);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs().format(dateFormat));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend'});
  }, [thermoSensorId]);

  useEffect(() => {
    if (refresh === true)
      customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend'});
    
    setRefresh(false);
  }, [refresh]);

  const customFetch = async (searchCriteria = {}) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/thermoMeasureLog/'+thermoSensorId, {
        params: searchCriteria
      }).then(response => {

        setEmptyGraphData(false);
        if (response.data.content.length === 0)
          setEmptyGraphData(true);

        response.data.content.sort(function(a,b){
          var dateA = new Date(a.createdOn).getTime();
          var dateB = new Date(b.createdOn).getTime();
          return dateA > dateB ? 1 : -1;  
        });

        var myJson = [];
        for (var k = 0; k < response.data.content.length; k++) {
          myJson.push({
            unit: response.data.content[k].unit,
            value: parseFloat(response.data.content[k].value),
            createdOn: dayjs(response.data.content[k].createdOn).format(dateTimeFromat)
          });
        }

        setGraphData(myJson);
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(dateFormat);
    if (date === null)
      changedDate = dayjs().format(dateFormat);
    
    setCurrentDate(changedDate);
    customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: changedDate});
  }

  const prevStep = () => {
    setCurrentDate(dayjs(currentDate).subtract(1,'d').format('YYYY-MM-DD'));
    customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: dayjs(currentDate).subtract(1,'d').format('YYYY-MM-DD')});
  }
  
  const nextStep = () => {
    if ( dayjs(dayjs(currentDate).format('YYYY-MM-DD')).isBefore(dayjs().format('YYYY-MM-DD'))) {
      setCurrentDate(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
      customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: dayjs(currentDate).add(1,'d').format('YYYY-MM-DD')});
    }
  }

  const refreshChart = () => {
    customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: currentDate});
  }

  const config = {
    data: graphData,
    xField: 'createdOn',
    yField: 'value',
    height: 200,
    color: '#F4664A',
    point: {
      shape: 'circle',
      style: ({ createdOn }) => {
        return {
          r: createdOn === dayjs().startOf("h").format("DD.MM.YYYY HH:mm") ? 6 : null,
          fill: 'white',
          stroke: '#028A0F',
          lineWidth: 2
        }
      },
    },
    /*
    label: {
      position: 'middle',
      content: function content(item) {
        return ''.concat(parseFloat(item.value).toFixed(1) + ' °C');
      }
    },
    */
    tooltip: {
      formatter: (x) => {
        return { name: t("page.measured_value"), value: parseFloat(x.value).toFixed(1) + ' °C' };
      },
    },
    annotations: [
      {
        type: 'regionFilter',
        start: ['min', '0'],
        end: ['max', '-150'],
        color: '#5B8FF9',
        stroke: '#5B8FF9'
      },{
        type: 'line',
        start: ['min', '0'],
        end: ['max', '0'],
        style: {
          color: '#5B8FF9',
          stroke: '#5B8FF9',
          lineDash: [2, 2],
        },
      },
    ],
  };

  return (
    <Spin spinning={isLoading}>    
      <Row style={{ paddingBottom: 20 }}>
        <Col flex={3}>
          <Row>
            <Col>
              <DatePicker value={dayjs(currentDate, dateFormat)} onChange={onDateChange} format="DD.MM.YYYY" />
            </Col>
          </Row>
        </Col>
        <Col flex={2}>
          <Row justify="end">
            <Col style={{ paddingRight: 5 }}>
              <Tooltip title={t("page.previous")} style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
              </Tooltip>
            </Col>
            <Col style={{ paddingRight: 5 }}>
              <Tooltip title={t("page.next")} style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
              </Tooltip>
            </Col>
            <Col style={{ paddingRight: 5 }}>
              <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {emptyGraphData ?
            <Empty />
          :
            <ReactG2Plot Ctor={Line} options={config} />
          }
        </Col>
      </Row>
    </Spin>
  );
};

export default ThermoSensorMeasureLogChart;