import { useState, useEffect } from 'react';
import { DatePicker, Empty, Spin, Tooltip, Button, Card, Space, Select, Row, Col } from 'antd';
import { useAxios } from '../../../../../../util/AxiosUtil';
import dayjs from 'dayjs';
import { Line, Column, DualAxes } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { useTranslation } from "react-i18next";
import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange, disabled3MonthsDate, disabled12MonthsDate } from '../../../../../../util/ChartUtil';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';

const LostOnSourceTab = ({ boilerRoomId, refresh, dateRange, changeDateRange, groupBy, changeGroupBy }) => {
    
    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { RangePicker } = DatePicker;
    
    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
    const [chartData, setChartData] = useState([]);
    const [emptyGraphData, setEmptyGraphData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [graphType, setGraphType] = useState("column");

    useEffect(() => {
      fetchData(dateRange[0], dateRange[1]);
    }, [boilerRoomId, dateRange, refresh, groupBy]);

    useEffect(() => {
      function handleResize() {
          setIsMobile(window.innerWidth < 700)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, []);

    const fetchData = async (dateFrom , dateTo) => {
      setIsLoading(true);

      try {
        const lostSource = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/lostOnSource/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
        const producedHeatAll = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/producedHeatAll/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));

        var lostSourceData = [];
        for (var k = 0; k < lostSource.data.length; k++) {
          if (lostSource.data[k].value !== null) {
            lostSourceData.push({
              value: lostSource.data[k].value,
              date: dayjs(lostSource.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy),
              type: t("Strata kWh")
            });
          }
        }

        var lostSourcePercData = [];
        for (var k = 0; k < producedHeatAll.data.length; k++) {
          for (var l = 0; l < lostSourceData.length; l++) {
            if (dayjs(producedHeatAll.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy) === lostSourceData[l].date) {
              lostSourcePercData.push({
                value: producedHeatAll.data[k].value > 0 ? lostSourceData[l].value / producedHeatAll.data[k].value : lostSourceData[l].value,
                type: t("Strata %"),
                date: lostSourceData[l].date
              });
            }
          }
        }
        
        var finalData = lostSourceData.concat(lostSourcePercData);

        setEmptyGraphData(false);
        if (finalData.length === 0)
          setEmptyGraphData(true);
  
        setChartData(finalData);
        setIsLoading(false);
      } catch(err) {
        console.log(err);
      }
    };

    const prevStep = () => {
      changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
    }
    
    const nextStep = () => {
      if (!dayjs(dateRange[1]).isSame(dayjs())) {
        changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
      } else {
        fetchData(dateRange[0], dateRange[1]);
      }
    }

    const refreshChart = () => {
      fetchData(dateRange[0], dateRange[1]);
    }

    const getChartUnit = (type, value) => {
      
      switch (type) {
        case t("Strata kWh"):
          return parseFloat(value).toFixed(2) + ' kWh';
        case t("Strata %"):
          return parseFloat(value).toFixed(2) + ' %';
      }
    }

    const config = {
      data: chartData,
      isGroup: true,
      seriesField: 'type',
      xField: 'date',
      yField: 'value',
      height: 300,
      tooltip: {
        formatter: (x) => {
          return { name: x.type, value: getChartUnit(x.type,  x.value) };
        },
      },
    };

    return (
      <Spin spinning={isLoading}>
        <Card
          size="small" loading={isLoading}
          style={{ marginTop: 10, paddingBottom: 15 }}
          title={
            <>
              <Row gutter={[5,10]}>
                <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                  <Select
                    onChange={changeGroupBy} 
                    defaultValue={{ value: groupBy }}
                    style={{ width: '100%', marginRight: 10 }}
                    options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                  />
                </Col>
                <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                  {groupBy === 'hour'?
                    <DatePicker 
                      value={dateRange[0]}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                      style={{ width: '100%', marginRight: 10 }}
                      format="DD.MM.YYYY" 
                      maxDate={dayjs()}
                    />
                  : groupBy === 'day' ?
                    <RangePicker 
                      value={dateRange}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                      style={{ width: '100%', marginRight: 10 }}
                      format={"DD.MM.YYYY"}
                      maxDate={dayjs()}
                      disabledDate={disabled3MonthsDate}
                    />
                  :
                    <RangePicker
                      value={dateRange}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                      style={{ width: '100%', marginRight: 10 }}
                      format={"MM.YYYY"} 
                      picker={"month"}
                      maxDate={dayjs()}
                      disabledDate={disabled12MonthsDate}
                    />
                  }
                </Col>
                <Col flex="auto">
                  <Row justify="end">
                    <Col>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                        </Tooltip>
                      </Space>
                      {/*
                      <Space style={{ paddingLeft: 10 }}>
                        <Tooltip title="Export" style={{ margin: 15 }} >
                          <Button type="dashes" icon={<DownloadOutlined />} />
                        </Tooltip>
                      </Space>
                      */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
          }>
          {emptyGraphData ?
            <Empty />
          :
          graphType === 'column' ?
            <ReactG2Plot Ctor={Column} options={config} />
          : <ReactG2Plot Ctor={Line} options={config} />
          }
        </Card>
      </Spin>
    );
};

export default LostOnSourceTab;