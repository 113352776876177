import { useState, useEffect } from 'react';
import { Empty, DatePicker, Tooltip, Card, Button, Select, Space, Spin, Row, Col } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange, disabled3MonthsDate, disabled12MonthsDate } from '../../../util/ChartUtil';

import dayjs from 'dayjs';
import { Line, Column } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';

const BatteryChargingChart = ({ batteryId, unit, refresh, dateRange, changeDateRange, groupBy, changeGroupBy }) => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { RangePicker } = DatePicker;
  
  const { i18n, t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [graphType, setGraphType] = useState("column");

  useEffect(() => {
    customFetch(dateRange[0], dateRange[1]);
  }, [batteryId, dateRange, unit, refresh, groupBy, t]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async (dateFrom, dateTo) => {
    
    setIsLoading(true);

    try {
      const charging = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/charging/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const discharging = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/discharging/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));

      var chargingData = [];
      for (var k = 0; k < charging.data.length; k++) {
        if (charging.data[k].value !== null) {
          chargingData.push({
            value: unit === 'kWh' ? charging.data[k].value : charging.data[k].value / 1000,
            type: t("page.charging"),
            date: dayjs(charging.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
          });
        }
      }
      
      var dischargingData = [];
      for (var k = 0; k < discharging.data.length; k++) {
        if (discharging.data[k].value !== null) {
          dischargingData.push({
            value: unit === 'kWh' ? discharging.data[k].value : discharging.data[k].value / 1000,
            type: t("page.discharging"),
            date: dayjs(discharging.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
          });
        }
      }
      var finalData = chargingData.concat(dischargingData);
      
      setEmptyGraphData(false);
      if (finalData.length === 0)
        setEmptyGraphData(true);

      setGraphData(finalData);
      setIsLoading(false);

    } catch(err) {
      console.log(err);
    }
  };

  const prevStep = () => {
    changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
  }
  
  const nextStep = () => {
    if (!dayjs(dateRange[1]).isSame(dayjs())) {
      changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
    } else {
      fetchData(dateRange[0], dateRange[1]);
    }
  }

  const refreshChart = () => {
    customFetch(dateRange[0], dateRange[1]);
  }

  const config = {
    data: graphData,
    isGroup: true,
    seriesField: 'type',
    xField: 'date',
    yField: 'value',
    height: 300,
    color: ['#0ebf13','#F4664A'],
    tooltip: {
      formatter: (x) => {
          return { 
            name: x.type,
            value: (unit === 'kWh' ? parseFloat(x.value).toFixed(4) + ' kWh' : parseFloat(x.value).toFixed(4) + ' MWh')
          };
      },
    },
    /*
    tooltip: {
      formatter: (x) => {
        return { name: x.value > 0 ? t("page.charging") : t("page.discharging"), value: parseFloat(x.value).toFixed(3) + ' kW' };
      },
    },
    */
    legend: false
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading}
        title={
          <>
            <Row gutter={[5,10]}>
              {/*
              <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                <Select
                  onChange={changeGroupBy} 
                  defaultValue={{ value: groupBy }}
                  style={{ width: '100%', marginRight: 10 }}
                  options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                />
              </Col>
              */}
              <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                <Select 
                  onChange={setGraphType} 
                  defaultValue={{ value: 'column', label: t("page.chart_column") }}
                  style={{ width: '100%', marginRight: 10 }}
                  options={[ { value: 'column', label: t("page.chart_column") }, { value: 'line', label: t("page.chart_line") } ]}  
                />
              </Col>
              <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                {groupBy === 'hour'?
                  <DatePicker 
                    value={dateRange[0]}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                    maxDate={dayjs()}
                  />
                : groupBy === 'day' ?
                  <RangePicker 
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"DD.MM.YYYY"}
                    maxDate={dayjs()}
                    disabledDate={disabled3MonthsDate}
                  />
                :
                  <RangePicker
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"MM.YYYY"} 
                    picker={"month"}
                    maxDate={dayjs()}
                    disabledDate={disabled12MonthsDate}
                  />
                }
              </Col>
              <Col flex="auto">
                <Row justify="end">
                  <Col>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                      </Tooltip>
                    </Space>
                    {/*
                    <Space style={{ paddingLeft: 10 }}>
                      <Tooltip title="Export" style={{ margin: 15 }} >
                        <Button type="dashes" icon={<DownloadOutlined />} />
                      </Tooltip>
                    </Space>
                    */}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
        }>
        {emptyGraphData ?
          <Empty />
        :
          graphType === 'column' ?
            <ReactG2Plot Ctor={Column} options={config} />
          : <ReactG2Plot Ctor={Line} options={config} />
        }
      </Card>
    </Spin>
  );
};

export default BatteryChargingChart;