import { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Empty, Spin, Tooltip, Button, Card, Space, Select } from 'antd';
import { useAxios } from '../../../../../../util/AxiosUtil';
import dayjs from 'dayjs';
import { Line, Column } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { useTranslation } from "react-i18next";
import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange, disabled3MonthsDate, disabled12MonthsDate } from '../../../../../../util/ChartUtil';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';

const QuantityEeFromFweTab = ({ boilerRoomId, unit, refresh, dateRange, changeDateRange, groupBy, changeGroupBy }) => {
    
    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { RangePicker } = DatePicker;

    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
    const [eeFromNetData, setEeFromNetData] = useState([]);
    const [eeFromFveData, setEeFromFveData] = useState([]);
    const [emptyGraphData, setEmptyGraphData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [graphType, setGraphType] = useState("column");

    useEffect(() => {
      fetchEeForTc(dateRange[0], dateRange[1]);
    }, [boilerRoomId, dateRange, unit, refresh, groupBy]);

    useEffect(() => {
      function handleResize() {
          setIsMobile(window.innerWidth < 700)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, []);

    const fetchEeForTc = async (dateFrom , dateTo) => {
        setIsLoading(true);
        await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/eeForTc/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)))
        .then(response => {

          setEmptyGraphData(false);
          if (response.data.length === 0)
            setEmptyGraphData(true);

          var eeForTc = [];
          for (var k = 0; k < response.data.length; k++) {
            if (response.data[k].value !== null) {
              eeForTc.push({
                //value: parseFloat(response.data[k].value),
                value: unit === 'kWh' ? response.data[k].value : response.data[k].value / 1000,
                date: dayjs(response.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
              });
            }
          }

          fetchEeFveConsume(dateFrom, dateTo, eeForTc);
        }).catch('error: ' + console.log);
    };

    const fetchEeFveConsume = async (dateFrom , dateTo, eeForTc) => {
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/eeFveConsume/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)))
      .then(response => {

        setEmptyGraphData(false);
        if (response.data.length === 0)
          setEmptyGraphData(true);

        var eeFromNet = [];
        for (var k = 0; k < response.data.length; k++) {
          for (var l = 0; l < eeForTc.length; l++) {
            if (dayjs(response.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy) === eeForTc[l].date && response.data[k].value !== null) {
              
              let eeFveVal = unit === 'kWh' ? response.data[k].value : response.data[k].value / 1000;
              eeFromNet.push({
                value: (eeForTc[l].value > eeFveVal) ? parseFloat(eeForTc[l].value) - parseFloat(eeFveVal) : parseFloat(eeFveVal),
                type: t("EE TČ zo siete"),
                date: dayjs(response.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
              });
            }
          }
        }

        var eeFromFve = [];
        for (var k = 0; k < eeFromNet.length; k++) {
          for (var l = 0; l < eeForTc.length; l++) {
            if (eeFromNet[k].date === eeForTc[l].date
            // && parseFloat(eeForTc[t].value) > parseFloat(eeFromNet[k].value)
            ) {
              //eeFromNet[k].value = eeForTc[t].value - eeFromNet[k].value;
              eeFromFve.push({
                value: eeForTc[l].value - eeFromNet[k].value,
                type: t("EE pre tepelné čerpadlá z FVE"),
                date: eeForTc[l].date
              });
            }
          }
        }

        setEeFromNetData(eeFromNet);
        setEeFromFveData(eeFromFve);
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const prevStep = () => {
      changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
    }
    
    const nextStep = () => {
      if (!dayjs(dateRange[1]).isSame(dayjs())) {
        changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
      } else {
        fetchEeForTc(dateRange[0], dateRange[1]);
      }
    }

    const refreshChart = () => {
      fetchEeForTc(dateRange[0], dateRange[1]);
    }

    const config = {
      data: eeFromNetData.concat(eeFromFveData),
      isGroup: true,
      seriesField: 'type',
      xField: 'date',
      yField: 'value',
      height: 300,
      tooltip: {
        formatter: (x) => {
            return { 
              name: x.type,
              value: (unit === 'kWh' ? parseFloat(x.value).toFixed(4) + ' kWh' : parseFloat(x.value).toFixed(4) + ' MWh')
            };
        },
      },
    };

    return (
      <Spin spinning={isLoading}>
        <Card
          size="small" loading={isLoading}
          style={{ marginTop: 10, paddingBottom: 15 }}
          title={
            <>
              <Row gutter={[5,10]}>
                <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                  <Select
                    onChange={changeGroupBy} 
                    defaultValue={{ value: groupBy }}
                    style={{ width: '100%', marginRight: 10 }}
                    options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                  />
                </Col>
                <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                  <Select 
                    onChange={setGraphType} 
                    defaultValue={{ value: 'column', label: t("page.chart_column") }}
                    style={{ width: '100%', marginRight: 10 }}
                    options={[ { value: 'column', label: t("page.chart_column") }, { value: 'line', label: t("page.chart_line") } ]} 
                  />
                </Col>
                <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                  {groupBy === 'hour'?
                    <DatePicker 
                      value={dateRange[0]}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))} 
                      style={{ width: '100%', marginRight: 10 }}
                      format="DD.MM.YYYY" 
                      maxDate={dayjs()}
                    />
                  : groupBy === 'day' ?
                    <RangePicker 
                      value={dateRange}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))} 
                      style={{ width: '100%', marginRight: 10 }}
                      format={"DD.MM.YYYY"}
                      maxDate={dayjs()}
                      disabledDate={disabled3MonthsDate}
                    />
                  :
                    <RangePicker
                      value={dateRange}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))} 
                      style={{ width: '100%', marginRight: 10 }}
                      format={"MM.YYYY"} 
                      picker={"month"}
                      maxDate={dayjs()}
                      disabledDate={disabled12MonthsDate}
                    />
                  }
                </Col>
                <Col flex="auto">
                  <Row justify="end">
                    <Col>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                        </Tooltip>
                      </Space>
                      {/*
                      <Space style={{ paddingLeft: 10 }}>
                        <Tooltip title="Export" style={{ margin: 15 }} >
                          <Button type="dashes" icon={<DownloadOutlined />} />
                        </Tooltip>
                      </Space>
                      */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
          }>
          {emptyGraphData ?
            <Empty />
          :
            graphType === 'column' ?
              <ReactG2Plot Ctor={Column} options={config} />
            : <ReactG2Plot Ctor={Line} options={config} />
          }
        </Card>
      </Spin>
    );
};

export default QuantityEeFromFweTab;