import { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Empty, Spin, Tooltip, Button, Card, Space } from 'antd';
import { useAxios } from '../../../../../util/AxiosUtil';
import dayjs from 'dayjs';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { useTranslation } from "react-i18next";

import { LeftOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';

const ElectricityTab = () => {
    
    const { i18n, t } = useTranslation();
    const dateFormat = "YYYY-MM-DD";
    const dateFormatDisp = "DD.MM.YYYY HH:ss";
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    
    const [currentDate, setCurrentDate] = useState(dayjs().hour() < 17 ? dayjs().format(dateFormat) : dayjs().add(1,'day').format(dateFormat));
    const [graphData, setGraphData] = useState([]);
    const [emptyGraphData, setEmptyGraphData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      customFetch(currentDate);
    }, []);
    
    const customFetch = async (startDate) => {
        setIsLoading(true);
        await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/okte/electricity/'+startDate)
        .then(response => {

          setEmptyGraphData(false);
          if (response.data.length === 0)
            setEmptyGraphData(true);

          var myJson = [];
          for (var k = 0; k < response.data.length; k++) {
            var period = response.data[k].period === '24' ? '00' : response.data[k].period;

            if (response.data[k].value !== null) {
              myJson.push({
                value: parseFloat(response.data[k].price),
                date: dayjs(response.data[k].bday+' '+period).format(dateFormatDisp)
              });
            }
          }

          setGraphData(myJson);
          setIsLoading(false);
        }).catch('error: ' + console.log);
    };

    function onDateChange(date) {
      var changedDate = dayjs(date).format(dateFormat);
      if (date === null)
        changedDate = dayjs().format(dateFormat);
      
      setCurrentDate(changedDate);
      customFetch(changedDate);
    }
  
    const prevStep = () => {
      setCurrentDate(dayjs(currentDate).subtract(1,'d').format('YYYY-MM-DD'));
      customFetch(dayjs(currentDate).subtract(1,'d').format('YYYY-MM-DD'));
    }
    
    const nextStep = () => {

      if (dayjs().hour() < 17 && !dayjs(currentDate).isSame(dayjs().format('YYYY-MM-DD'))) {
        setCurrentDate(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
        customFetch(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
      } else if (dayjs().hour() > 17 && !dayjs(currentDate).isSame(dayjs().add(1,'d').format('YYYY-MM-DD'))) {
        setCurrentDate(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
        customFetch(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
      } else {
        customFetch(currentDate);
      }
    }

    const refreshChart = () => {
      customFetch(currentDate);
    }

    const config = {
      data: graphData,
      xField: 'date',
      yField: 'value',
      height: 300,
      label: {},
      point: {
        size: 5,
        shape: 'diamond',
        style: {
          fill: 'white',
          stroke: '#5B8FF9',
          lineWidth: 2,
        },
      },
      tooltip: {
        formatter: (x) => {
          return { name: t("page.price"), value: x.value + ' €/MWh'};
        },
      },
      state: {
        active: {
          style: {
            shadowBlur: 4,
            stroke: '#000',
            fill: 'red',
          },
        },
      },
    };

    return (
      <Spin spinning={isLoading}>
        <Card
          size="small" loading={isLoading}
          style={{ marginTop: 10, paddingBottom: 15 }}
          title={
            <>
              <Row gutter={[5,10]}>
                <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                  <DatePicker 
                    value={dayjs(currentDate, dateFormat)}
                    onChange={onDateChange}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                  />
                </Col>
                <Col flex="auto">
                  <Row justify="end">
                    <Col>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                        </Tooltip>
                      </Space>
                      {/*
                      <Space style={{ paddingLeft: 10 }}>
                        <Tooltip title="Export" style={{ margin: 15 }} >
                          <Button type="dashes" icon={<DownloadOutlined />} />
                        </Tooltip>
                      </Space>
                      */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
          }>
          {emptyGraphData ?
            <Empty />
          :
            <ReactG2Plot Ctor={Line} options={config} />
          }
        </Card>
      </Spin>
    );
};

export default ElectricityTab;