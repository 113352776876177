import { useState, useEffect, useCallback } from 'react';
import { Row, Col, DatePicker, Empty, Spin, Tooltip, Button, Card, Space } from 'antd';
import { useAxios } from '../../../../../util/AxiosUtil';
import dayjs from 'dayjs';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import isInRole from '../../../../../security/IsInRole';
import { useTranslation } from "react-i18next";

import { LeftOutlined, RightOutlined, PlusCircleOutlined, SyncOutlined } from '@ant-design/icons';

import GassInsert from './GassInsert';

const GassTab = () => {
  
  const { i18n, t } = useTranslation();
  const dateFormat = "YYYY-MM-DD";
  const dateFormatDisp = "MM.YYYY";
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  
  const [currentDate, setCurrentDate] = useState(dayjs().format(dateFormat));
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);

  useEffect(() => {
    customFetch(currentDate);
  }, [refreshForm]);
  
  const customFetch = async (startDate) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/okte/gass/'+startDate)
      .then(response => {

        setEmptyGraphData(false);
        if (response.data.length === 0)
          setEmptyGraphData(true);

        var myJson = [];
        for (var k = 0; k < response.data.length; k++) {
          if (response.data[k].price !== null) {
            myJson.push({
              value: parseFloat(response.data[k].price),
              date: dayjs(response.data[k].month).format(dateFormatDisp)
            });
          }
        }

        setGraphData(myJson);
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(dateFormat);
    if (date === null)
      changedDate = dayjs().format(dateFormat);
    
    setCurrentDate(changedDate);
    customFetch(changedDate);
  }

  const prevStep = () => {
    setCurrentDate(dayjs(currentDate).subtract(1,'M').format('YYYY-MM-DD'));
    customFetch(dayjs(currentDate).subtract(1,'M').format('YYYY-MM-DD'));
  }
  
  const nextStep = () => {
    if (!dayjs(currentDate).isSame(dayjs().format('YYYY-MM-DD'))) {
      setCurrentDate(dayjs(currentDate).add(1,'M').format('YYYY-MM-DD'));
      customFetch(dayjs(currentDate).add(1,'M').format('YYYY-MM-DD'));
    } else {
      customFetch(currentDate);
    }
  }

  const refreshChart = () => {
    customFetch(currentDate);
  }

  const showInsertForm = useCallback((value) => {
    if (value === 'refresh') {
      setVisibleForm(false);
      setRefreshForm(true);
    } else {
      setVisibleForm(value);
    }
  }, []);

  const config = {
    data: graphData,
    xField: 'date',
    yField: 'value',
    height: 300,
    label: {},
    stepType: 'hvh',
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    tooltip: {
      formatter: (x) => {
        return { name: t("page.price"), value: x.value + ' €/MWh'};
      },
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
  };

  return (
    <Spin spinning={isLoading}>
      <Card size="small"
        loading={isLoading}
        extra={
          isInRole(['enerpo_admin']) ? 
            <>
              <Button type="text" icon={<PlusCircleOutlined style={{ marginRight: 5 }} />} onClick={showInsertForm}>{t("page.price_add")}</Button> 
            </>
          : <></>
        }
      >
        <Row gutter={[5,10]} style={{ paddingBottom: 10 }}>
          <Col span={6} xs={24} md={6} xl={6} xxl={6}>
            <DatePicker 
              value={dayjs(currentDate, dateFormat)}
              onChange={onDateChange}
              style={{ width: '100%', marginRight: 10 }}
              format="MM.YYYY" 
              picker="month"
            />
          </Col>
          <Col flex="auto">
            <Row justify="end">
              <Col>
                <Space style={{ paddingRight: 5 }}>
                  <Tooltip style={{ margin: 15 }} >
                    <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                  </Tooltip>
                </Space>
                <Space style={{ paddingRight: 5 }}>
                  <Tooltip style={{ margin: 15 }} >
                    <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                  </Tooltip>
                </Space>
                <Space style={{ paddingRight: 5 }}>
                  <Tooltip style={{ margin: 15 }} >
                    <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {emptyGraphData ?
              <Empty />
            :
              <ReactG2Plot Ctor={Line} options={config} />
            }
          </Col>
        </Row>
        <GassInsert setVisible={showInsertForm} visible={visibleForm} />
      </Card>
    </Spin>
  );
};

export default GassTab;