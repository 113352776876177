import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { Pie } from '@antv/g2plot';
import { useAxios } from '../../util/AxiosUtil';

const CompanyBoilerRoomCnt = () => {
    
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, []);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom/cntByCompany')
      .then(response => {

        var myJson = [];
        for (var k = 0; k < response.data.length; k++) {
          if (response.data[k].cnt !== null) {
            myJson.push({
              value: response.data[k].cnt,
              type: response.data[k].name
            });
          }
        }

        setGraphData(myJson);
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const config = {
      appendPadding: 10,
      legend: false,
      data: graphData,
      angleField: 'value',
      colorField: 'type',
      radius: 0.8,
      height: 205,
      label: {
        type: 'outer',
      },
      interactions: [
        {
          type: 'pie-legend-active',
        },
        {
          type: 'element-active',
        },
      ],
    };

    return (
      <Spin spinning={isLoading}>
        {!isLoading ?
        <>
          <Pie {...config} />
        </>:<></>}
      </Spin>
    );
};

export default CompanyBoilerRoomCnt;