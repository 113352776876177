import { useState, useEffect } from 'react';
import { Empty, DatePicker, Tooltip, Card, Button, Select, Space, Spin, Row, Col } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange } from '../../../util/ChartUtil';

import dayjs from 'dayjs';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { LeftOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';

const FweAllProductionLineChart = ({ fweIds, unit, dateRange, changeDateRange, groupBy, changeGroupBy }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { RangePicker } = DatePicker;
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dateRange !==null)
      customFetch(dateRange[0], dateRange[1]);
  }, [fweIds, dateRange, unit, groupBy]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async (dateFrom, dateTo) => {
    
      const ids = fweIds.length > 0 ? fweIds : 0;
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/fwe/'+ids+'/productions/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)))
      .then(response => {
        setEmptyGraphData(false);
        if (response.data.length === 0)
          setEmptyGraphData(true);

        var myJson = [];
        for (var k = 0; k < response.data.length; k++) {
          if (response.data[k].value !== null) {
            myJson.push({
              value: unit === 'kWh' ? response.data[k].value : response.data[k].value / 1000,
              type: response.data[k].name,
              date: dayjs(response.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
            });
          }
        }

        let keys = ['date'];
        let res = Object.values(myJson.reduce((r, o) => {
            const key = keys.map(k => o[k]).join('|');
            if (!r[key]) r[key] = { ...o, value: 0 };
            r[key].value += o.value;
            r[key].type = t("page.sum");
            return r;
        }, {}));
        myJson.push(...res);
        
        setGraphData(myJson);
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  const prevStep = () => {
    changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
  }
  
  const nextStep = () => {
    if (!dayjs(dateRange[1]).isSame(dayjs())) {
      changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
    } else {
      fetchData(dateRange[0], dateRange[1]);
    }
  }

  const refreshChart = () => {
    customFetch(dateRange[0], dateRange[1]);
  }

  const config = {
    data: graphData,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    legend: false,
    height: 300,
    tooltip: {
      formatter: (x) => {
        return { 
          name: x.type, 
          value: (unit === 'kWh' ? parseFloat(x.value).toFixed(4) + ' kWh' : parseFloat(x.value).toFixed(4) + ' MWh')
        };
      },
    },
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading}
        title={
          <>
            <Row gutter={[5,10]}>
              <Col span={4} xs={24} md={4} xl={4} xxl={4}>
                <Select
                  onChange={changeGroupBy} 
                  defaultValue={{ value: groupBy }}
                  style={{ width: '100%', marginRight: 10 }}
                  options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                />
              </Col>
              <Col span={8} xs={24} md={8} xl={8} xxl={8}>
                {groupBy === 'hour'?
                  <DatePicker 
                    value={dateRange[0]}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                    disabledDate={(current) => { 

                      if (!dateRange) return false;

                      const tooLate = dateRange[0] && current.diff(dateRange[0], 'day') === 0;
                      return !!tooLate || dayjs(dayjs()).isBefore(dayjs(current));
                    }}
                  />
                : groupBy === 'day' ?
                  <RangePicker 
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"DD.MM.YYYY"}
                    disabledDate={(current) => { 

                      if (!dateRange) return false;

                      const tooLate = dateRange[0] && current.diff(dateRange[0], 'month') >= 3;
                      const tooEarly = dateRange[1] && dateRange[1].diff(current, 'month') >= 3;
                      return !!tooEarly || !!tooLate || dayjs(dayjs()).isBefore(dayjs(current));
                    }}
                  />
                :
                  <RangePicker
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    //onOpenChange={(val) => !val ? changeDateRange([dateFrom, dateTo]) : null}
                    format={"MM.YYYY"} 
                    picker={"month"}
                    disabledDate={(current) => { 

                      if (!dateRange) return false;

                      const tooLate = dateRange[0] && current.diff(dateRange[0], 'month') >= 12;
                      const tooEarly = dateRange[1] && dateRange[1].diff(current, 'month') >= 12;
                      return !!tooEarly || !!tooLate || dayjs(dayjs()).isBefore(dayjs(current));
                    }}
                  />
                }
              </Col>
              <Col flex="auto">
                <Row justify="end">
                  <Col>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }>
        {emptyGraphData ?
          <Empty style={{ height: 300 }}/>
        :
          <ReactG2Plot Ctor={Line} options={config} />
        }
      </Card>
    </Spin>
  );
};

export default FweAllProductionLineChart;