import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { Gauge } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { useAxios } from '../../../util/AxiosUtil';

const FweActualPowerChart = ({ fweId, maxPower }) => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [power, setPower] = useState(0);  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch();
  }, [fweId]);

  const customFetch = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/fwe/'+fweId+'/power')
    .then(response => {
      setPower(response.data);
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const config = {
    percent: power/maxPower,
    height: 150,
    range: {
      //color: '#30BF78',
      color: '#F4664A',
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return parseFloat(Number(v) * maxPower).toFixed(3);
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      content: {
        formatter: ({ percent }) => `${(parseFloat(power).toFixed(3))} kW`,
        style: {
          color: 'rgba(0,0,0,0.65)',
          fontSize: 12,
        },
      },
    },
  };

  return (
    <Spin spinning={isLoading}>
      <ReactG2Plot Ctor={Gauge} options={config} />
    </Spin>
  );
};

export default FweActualPowerChart;