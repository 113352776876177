import { useState, useEffect } from 'react';
import { Empty, Tooltip, Card, Button, Space, Spin } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";

import dayjs from 'dayjs';
import { Pie, Column } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { SyncOutlined } from '@ant-design/icons';

const HpAllAvgCopColumnChart = ({ heatPumpIds }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch();
  }, [heatPumpIds]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async () => {

    const ids = heatPumpIds.length > 0 ? heatPumpIds : 0;
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/heatPump/'+ids+'/avgCops')
    .then(response => {
      setEmptyGraphData(false);
      if (response.data.length === 0)
        setEmptyGraphData(true);

      var myJson = [];
      for (var k = 0; k < response.data.length; k++) {
        if (response.data[k].value !== null) {
          myJson.push({
            value: response.data[k].value,
            type: response.data[k].name,
            date: dayjs(response.data[k].date)
          });
        }
      }

      setGraphData(myJson);
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const refreshChart = () => {
    customFetch();
  }

  const config = {
    data: graphData,
    xField: 'type',
    yField: 'value',
    height: 300,
    legend: false,
    //innerRadius: 0.6,
    label: false,
    tooltip: {
      formatter: (x) => {
        return { 
          name: x.type,
          value: parseFloat(x.value).toFixed(2)
        };
      },
    },
    color: ({ value }) => {
      if (value < '3') {
        return '#cf1322';
      }
      return '#3f8600';
    },
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading} title={t("page.avg_cop") + " " + dayjs().format("DD.MM.YYYY") +":"}
        extra={
            <div style={{ paddingTop: 16, paddingBottom: 16 }}>
              <Space style={{ paddingRight: 5 }}>
                <Tooltip style={{ margin: 15 }} >
                  <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                </Tooltip>
              </Space>
            </div>
        }>
          {emptyGraphData ?
            <Empty style={{ height: 300 }} />
          :
            <ReactG2Plot Ctor={Column} options={config} />
          }
      </Card>
    </Spin>
  );
};

export default HpAllAvgCopColumnChart;