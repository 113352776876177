import { useState, useEffect, useCallback } from 'react';
import { Tabs, Card, Row, Col, Typography, Select, Space, Breadcrumb } from 'antd';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import { getWeather } from '../../util/WeatherUtil';

import ElectricityTab from '../boiler_room/tabs/prices/sub_tabs/ElectricityTab';
import GassTab from '../boiler_room/tabs/prices/sub_tabs/GassTab';

import TemperatureChart from './charts/TemeratureChart';
import CopChart from './charts/CopChart';
import HeatProductionChart from './charts/HeatProductionChart';
import ControlChart from './charts/ControlChart';

import HpStatusChart from './charts/HpStatusChart';
import HpPowerChart from './charts/HpPowerChart';
import CopTinyChart from './charts/CopTinyChart';

import dayjs from 'dayjs';

import { HomeOutlined } from '@ant-design/icons';

import HpTodayProductionCard from './chartCards/HpTodayProductionCard';
import HpTodayEnergyCard from './chartCards/HpTodayEnergyCard';
import HpTotalProductionCard from './chartCards/HpTotalProductionCard';
import HpTotalEnergyCard from './chartCards/HpTotalEnergyCard';

const HeatPump = () => {
    
    let params = useParams();
    const { TabPane } = Tabs;
    const { Title } = Typography;
    const { Paragraph } = Typography;

    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

    const [heatPumpData, setHeatPumpData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [heatPumpListData, setHeatPumpListData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isHeatPumpsLoading, setIsHeatPumpsLoading] = useState(false);
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

    const [groupBy, setGroupBy] = useState("hour");
    const [selectedKey, setSelectedKey] = useState();
    const [selectedUnit, setSelectedUnit] = useState('kWh');

    const [activeKey, setActiveKey] = useState(1);
    const [items, setItems] = useState();

    const changeUnitAction =
      <Select 
        onChange={setSelectedUnit} 
        defaultValue={{ value: 'column', label: 'kWh' }}
        style={{ width: isMobile ? '100%' : 80 }} 
        options={[ { value: 'kWh', label: 'kWh' }, { value: 'MWh', label: 'MWh' } ]}
      />;

    useEffect(() => {
      customFetch();
    }, [params.id]);

    useEffect(() => {
      function handleResize() {
          setIsMobile(window.innerWidth < 700)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, []);

    const changeActiveKey = (key) => {
      setActiveKey(key);
    }

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/heatPump/'+params.id)
      .then(response => {
          setHeatPumpData(response.data);
          setBoilerRoomData(response.data.boilerRoom);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const fetchCompanyHeatPumps = async (companyId) => {

      setIsHeatPumpsLoading(true);
      setHeatPumpListData([]);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/heatPump?limit=100&search=companyId:'+companyId)
      .then(response => {

        var myJson = [];
        for (var k = 0; k < response.data.content.length; k++) {
          if (response.data.content[k].id !== heatPumpData.id) {
            myJson.push({
              value: response.data.content[k].id,
              label: response.data.content[k].name
            });
          }
        }

        setHeatPumpListData(myJson);
        setIsHeatPumpsLoading(false);
      }).catch('error: ' + console.log);
    };

    const changeHeatPumps = (id) => {
      navigate(process.env.REACT_APP_ENERPO_URL + '/heatPump/' + id);
    };

    const changeTab = (tab) => {
      setSelectedKey(tab);
    };

    const changeDateRange = useCallback((value) => {
      if (value) setDateRange(value);
    }, []);

    const changeGroupBy = useCallback((value) => {
      setGroupBy(value);
      value === 'hour' ? setDateRange([dayjs(), dayjs()]) 
      : value === 'day' ? setDateRange([dayjs().subtract(14, 'd'), dayjs()]) 
      : setDateRange([dayjs().subtract(4, 'M'), dayjs()]);
    }, []);

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Breadcrumb
          items={[
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/"}><HomeOutlined /></Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/company/"+boilerRoomData.companyId}> {boilerRoomData.companyName}</Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/"+boilerRoomData.id}>{boilerRoomData.name}</Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/heatPump"}>{t("page.heat_pumps")}</Link>, },
          ]}
        />

        <Row>
            <Col span={24} style={{ paddingTop: 10, paddingBottom: 20 }}>
                <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>
                  
                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>  
                      <Col span={18} xs={24} xl={18} style={{display: 'flex' }}>
                        <Space style={{ paddingLeft: 2 }}>
                          <Title level={5}>
                            {t("page.heat_pump") + ": " + heatPumpData.name + " - " + boilerRoomData.companyName}
                          </Title>
                        </Space>
                      </Col>
                      <Col span={6} xs={24} xl={6} style={{display: 'flex' }}>
                        <Select
                          placeholder={t("page.heat_pump_change")}
                          optionFilterProp="children"
                          style={{width: '100%'}}
                          loading={isHeatPumpsLoading}
                          onFocus={() => fetchCompanyHeatPumps(boilerRoomData.companyId)}
                          options={heatPumpListData}
                          onChange={changeHeatPumps}
                        >
                        </Select>
                      </Col>
                  </Row>

                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>
                    <Col span={6} xs={24} xl={6}>
                      <HpTodayProductionCard heatPumpId={heatPumpData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <HpTodayEnergyCard heatPumpId={heatPumpData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <HpTotalProductionCard heatPumpId={heatPumpData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <HpTotalEnergyCard heatPumpId={heatPumpData.id} />
                    </Col>
                  </Row>

                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>
                    {boilerRoomData.hasThermoSensor ? 
                      <Col span={8} xs={24} xl={8} style={{display: 'flex'}}>
                        <Card size='small'
                          style={{width: '100%'}}
                          bordered={true} 
                          loading={isLoading}
                        >
                          { getWeather(boilerRoomData.id, false) }
                        </Card>
                      </Col>
                    : <></> }
                    <Col span={4} xs={24} xl={4} style={{display: 'flex'}}>
                      <Card size='small'
                        style={{width: '100%'}}
                        bordered={true} 
                        loading={isLoading}
                      >
                        <HpPowerChart heatPumpId={heatPumpData.id} />
                      </Card>
                    </Col>
                    <Col span={4} xs={24} xl={4} style={{display: 'flex'}}>
                      <Card size='small'
                        style={{width: '100%'}}
                        bordered={true} 
                        loading={isLoading}
                      >
                        <HpStatusChart heatPumpId={heatPumpData.id} />
                      </Card>
                    </Col>

                    <Col span={8} xs={24} xl={8} style={{display: 'flex'}}>
                      <Card size='small'
                        style={{width: '100%'}}
                        bordered={true} 
                        loading={isLoading}
                      >
                        <CopTinyChart boilerRoomId={boilerRoomData.id} />
                      </Card>
                    </Col>
                  </Row>

                  <Row gutter={[10,10]}>

                    {isMobile ?
                      <Col span={24} style={{ marginBottom: 10 }}>
                        {changeUnitAction}
                      </Col>
                    : <></>}
                    
                    <Col span={24}>

                      <Tabs 
                        type="card" 
                        onTabClick={() => changeTab()} 
                        activeKey={selectedKey} 
                        destroyInactiveTabPane={true}
                        tabBarExtraContent={!isMobile ? changeUnitAction : <></>}
                      >
                        <TabPane tab={t("Výrobné parametre")} key="paramsTab">
                          <Card size='small' styles={{body : {paddingTop: 0}}} loading={isLoading}>
                            <Tabs
                              defaultActiveKey={activeKey}
                              onChange={(v) => changeActiveKey(v)}
                              items={[
                                {
                                  key: '1',
                                  label: t("Teplota"),
                                  children: <TemperatureChart boilerRoomId={boilerRoomData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                                },{
                                  key: '2',
                                  label: t("COP"),
                                  children: <CopChart boilerRoomId={boilerRoomData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                                },{
                                  key: '3',
                                  label: t("Výroba tepla"),
                                  children: <HeatProductionChart boilerRoomId={boilerRoomData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                                }
                              ]}
                            />
                          </Card>
                        </TabPane>
                        <TabPane tab={t("Ceny energetických vstupov")} key="pricesTab">
                          <Card size='small' styles={{body : {paddingTop: 0}}} loading={isLoading}>
                            <Tabs
                              defaultActiveKey={activeKey}
                              onChange={(v) => changeActiveKey(v)}
                              items={[
                                {
                                  key: '1',
                                  label: t("Elektrina"),
                                  children: <ElectricityTab />
                                },{
                                  key: '2',
                                  label: t("Plyn"),
                                  children: <GassTab />
                                }
                              ]}
                            />
                          </Card>
                        </TabPane>
                        <Tabs type="card" tab={t("Riadenie energetických vstupov")} key="controlTab">
                          <ControlChart boilerRoomId={boilerRoomData.id} unit={selectedUnit} refresh={isLoading} />
                        </Tabs>
                      </Tabs>
                    </Col>
                  </Row>

                </Card>
            </Col>
        </Row>
      </>
    );
};

export default HeatPump;